import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react'
import { Switch, Route, Link, useHistory } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import Clock from 'react-live-clock'

import Login from './components/Login'
import Register from './components/Register'
import Main from './components/Main'
import Menu from './components/Menu'
import Clients from './components/Clients'
import Orders from './components/Orders'
import SettingsGeneral from './components/SettingsGeneral'
import SettingsDelivery from './components/SettingsDelivery'
import SettingsEmployees from './components/SettingsEmployees'
import SettingsPrinters from './components/SettingsPrinters'
import Dashboard from './components/Dashboard'
import DriversStatus from './components/DriversStatus'
import Report from './components/Report'
import Employees from './components/Employees'
import Compiti from './components/Compiti'
import Ricette from './components/Ricette'
import Coupon from './components/Coupon'

import Services from './services.js'
import OrderConstants from './enums/OrderConstants'
import ItemCategories from './enums/ItemCategories'
import useToken from './useToken'
import sound from './confirm.mpeg'
import './index.css'
import 'moment/locale/it'

const axios = require('axios')
const moment = require('moment')
const prettyHour = (hour) => {
    return hour.split('T')[1].substr(0, 5)
}


function App() {
    // GESTIONE UTENTE
    const { token, removeToken, setToken } = useToken()
    const [user, setUser] = useState(() => {
        const loggedInUser = localStorage.getItem('user')
        if (loggedInUser) {
            return JSON.parse(loggedInUser)
        }
        return null
    })

    async function login(user = null, accessToken = null, refreshToken = null) {
        if (accessToken && refreshToken) {
            console.log("refresh token effettuato - dopo login")
            setToken(JSON.stringify({ accessToken: accessToken, refreshToken: refreshToken }))
        }

        setUser(user)
        localStorage.setItem('user', JSON.stringify(user))
    }

    async function logout() {
        removeToken()
        setUser(null)
        localStorage.clear()
        sessionStorage.removeItem('mode')
    }

    async function subscriptions(user) {
        if (user && !user.subscriptionStatus) {
            // se user risulta disabilitato faccio un checkSubscriptions per verificare se ha pagato dopo il cron job
            await Services.checkSubscriptions()
                .then((response) => {
                    console.log("checking subscriptions")
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        // richiamo updateRole per sapere se subscriptionStatus è cambiato
        await Services.updateRole(user.username, "").then((response) => {
            if (response.data.user?.subscriptionStatus !== user?.subscriptionStatus) {
                console.log("subscriptionStatus has changed")
                setUser(response.data.user)
                localStorage.setItem('user', JSON.stringify(response.data.user))
            }
        })
        .catch((error) => {
            console.log(error.response?.data?.error)
        })
    }

    // REFRESH TOKEN AL CARICAMENTO
    useLayoutEffect(() => {
        console.log(user?.username, "REFRESH")
        if (user?.username) {
            Services.refresh().then((response) => {
                console.log("refresh token effettuato - al caricamento")
                setToken(JSON.stringify({ accessToken: response.data.access_token, refreshToken: response.data.refresh_token }))

                subscriptions(user)
            })
            .catch((error) => {
                console.log(error.response)

                // se anche refresh_token è scaduto, disconnetto l'utente
                if (error.response?.status === 401 || error.response?.status === 422) {
                    logout()
                }
            })
        }
    }, [])

    // Response interceptor
    const myInterceptor = axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if ((error.response?.status === 401 || error.response?.status === 422) && error.request?.responseURL.includes("refresh") === false) {
            // Reentry code
            window.location.reload()  // ricaricando la pagina triggero refresh()
        }
        throw error  // se l'errore viene da refresh() gli rilancio l'errore per fargli fare il logout
    })

    // MODIFICA DI UN ORDINE GIÀ SALVATO (VARIABILE CONDIVISA TRA MAIN E ORDERS)
    const [orderData, setOrderData] = useState(null)

    async function modify(od) {
        setOrderData(od)
        this.history.push('/new-order')  // this si riferisce alle props di Orders
    }

    /* useEffect(() => {
        console.log(orderData)
    }, [orderData]) */

    // RECUPERO MENU
    const [menu, setMenu] = useState([])
    const [triggerMenuUpdate, setTriggerMenuUpdate] = useState(0)

    useEffect(() => {
        async function retrieveAllMenu() {
            if (user?.username) {
                return Services.getAllMenu(user.username)
                    .then((response) => {
                        setMenu(response.data.menu)
                    })
                    .catch((error) => {
                        console.log(error)
                        setMenu([])
                    })
            } else {
                setMenu([])
            }
        }

        retrieveAllMenu().then(() => {
            console.log("menu memorizzato")
        }, (err) => console.log(err))
    }, [user?.username, triggerMenuUpdate])

    // RECUPERO ANAGRAFICHE
    const [clients, setClients] = useState(null)
    const [triggerClientsUpdate, setTriggerClientsUpdate] = useState(0)

    useEffect(() => {
        async function retrieveClients() {
            if (user?.username) {
                return Services.getRegistry(user.username)
                    .then((response) => {
                        setClients(response.data.clients)
                    })
                    .catch((error) => {
                        console.log(error)
                        setClients([])
                    })
            } else {
                setClients([])
            }
        }

        if (triggerClientsUpdate) {  // si triggera solo quando si inserisce/aggiorna in reg (clientInsertOrUpdate) e al primo ingresso in Main
            retrieveClients().then(() => {
                console.log("clienti memorizzati")
            }, (err) => console.log(err))
        }
    }, [triggerClientsUpdate])

    // RECUPERO IMPOSTAZIONI E GESTIONE STAMPANTI
    // TODO: stampa da finestra di dialogo se non vengono dati
    const settingsRef = useRef(null)
    const [settings, setSettings] = useState(null)
    const version = useRef("")
    const map = useRef(false)
    const mapInterval = useRef(null)
    const [printers, setPrinters] = useState({"COMANDE": [], "FISCALE": []})
    const [connectionStatus, setConnectionStatus] = useState("{}")  // variabile di tipo stringa per triggerare SettingsPrinters

    const setMap = (m) => {
        map.current = m

        if (m) {
            retrieveSettings().then(() => {}, (err) => console.log(err))
            mapInterval.current = setInterval(retrieveSettings, 20 * 1000)
        } else {
            clearInterval(mapInterval.current)
        }
    }

    async function retrieveSettings() {
        console.log(user?.username, "SETTINGS")
        if (user?.username) {
            return Services.getSettings(user.username, version.current, map.current)
                .then((response) => {
                    // console.log(response, map.current)
                    if (response.status === 204) {
                        return
                    }

                    setSettings(response.data.settings)
                    settingsRef.current = response.data.settings
                    version.current = response.data.version
                    // console.log(version.current)

                    let printers = {"COMANDE": [], "FISCALE": []}
                    for (let i in response.data.settings?.printers) {
                        printers[response.data.settings.printers[i].typology].push(response.data.settings.printers[i])
                    }
                    setPrinters(printers)
                })
                .catch((error) => {
                    console.log(error)
                    setSettings(null)
                    settingsRef.current = null
                    version.current = ""
                    setPrinters({"COMANDE": [], "FISCALE": []})
                })
        } else {
            setSettings(null)
            settingsRef.current = null
            version.current = ""
            setPrinters({"COMANDE": [], "FISCALE": []})
        }
    }

    useEffect(() => {
        retrieveSettings().then(() => {}, (err) => console.log(err))
    }, [user?.username])

    const socket = useRef(null)

    useEffect(() => {
        function connect() {
            socket.current = new WebSocket('ws://127.0.0.1:6441')
            socket.current.onopen = function() {
                // console.log('Socket is connected.')
                setConnectionStatus(JSON.stringify({ppp: 'Il server PPP è connesso.'}))
            }

            socket.current.onclose = function(e) {
                setConnectionStatus(JSON.stringify({ppp: 'Impossibile stabilire la connessione con il server PPP!'}))
                // console.log('Socket is closed. Reconnect will be attempted in 5 seconds.', e.reason)
                setTimeout(function() {
                    connect()
                }, 5 * 1000)
            }

            socket.current.onerror = function(err) {
                // console.error('Socket encountered error: ', err.message, 'Closing socket')
                socket.current.close()
            }
        }

        connect()
    }, [])

    function receiptData(order, numOrders) {
        const receipt = {}

        receipt.info = ""
        receipt.notes = "\nNote: "
        receipt.reverseNotes = ""
        receipt.info += `ID: #${order.seq_value}\n`
        receipt.info += `Numero di telefono: ${order.reg.phone}\n`
        receipt.info += `Nome: ${order.reg.name}\n`
        if (order.reg.address && order.mode === OrderConstants.MODE_CONSEGNA) {
            receipt.info += `Indirizzo: ${order.reg.address}\n`
            receipt.info += `Distanza: ${order.reg.distance.space} - ${order.reg.distance.time}\n`
        }
        if (order.reg.notes) {
            receipt.reverseNotes += `  ${order.reg.notes}  \n`
        }
        receipt.info += "\nPagamento: "

        receipt.payment = `  ${order.payment}  \n\n`
        receipt.reversePayment = false
        if (order.payment !== "Contanti") {
            receipt.reversePayment = true;
        }

        receipt.items = ""
        const filteredItems = order.items.filter((i) => i.category !== OrderConstants.DELIVERY_COSTS)
        for (let i in filteredItems) {
            let itemName = `${filteredItems[i].quantity}x ${filteredItems[i].name}`
            const itemPrice = `${filteredItems[i].finalPrice.toFixed(2)} €`
            if (itemName.length + itemPrice.length + 2 <= 48) {
                const spaces = " ".repeat(48 - (itemName.length + itemPrice.length + 2))
                receipt.items += `${itemName} ${spaces}${itemPrice}\n`
            } else {
                // tronco itemName
                itemName = `${filteredItems[i].quantity}x ${filteredItems[i].name.slice(0, 48 - (itemName.length + itemPrice.length + 2))}`
                receipt.items += `${itemName} ${itemPrice}\n`
            }
            const add = [...filteredItems[i].add]
            for (let a in add) {
                receipt.items += `    Aggiunta ${add[a].name}\n`
            }
            const rem = [...filteredItems[i].rem]
            for (let r in rem) {
                receipt.items += `    Senza ${rem[r].name}\n`
            }
            if (filteredItems[i].notes) {
                receipt.items += `    Note: ${filteredItems[i].notes}\n`
            }
        }

        receipt.mode = ""
        if (order.items.find((i) => i.category === OrderConstants.DELIVERY_COSTS && i.finalPrice > 0)) {
            receipt.mode += `Spese di consegna: € ${order.items.find((i) => i.category === OrderConstants.DELIVERY_COSTS)?.finalPrice?.toFixed(2)}\n`
        }

        receipt.details = ""
        receipt.reverseHour = ""
        receipt.details += `Totale: € ${order.total.toFixed(2)}\n\n${order.mode}\n\nOrario: `
        if (order.mode === OrderConstants.MODE_CONSEGNA) {
            receipt.details += `${order.hour.split('T')[1].substr(0, 5)} `
    
            const d = moment.utc(order.hour)  // devo trattare l'ora come utc perché sul server è salvata così
            d.subtract(15, 'm')
            receipt.reverseHour += `  ${d.format('HH:mm')}  \n`
        } else {
            receipt.details += `${order.hour.split('T')[1].substr(0, 5)}\n`
        }

        receipt.footer = ""
        let plates = 0
        for (let j in order.items) {
            if (order.items[j]?.category === ItemCategories.PIZZE) {
                plates += order.items[j].quantity
            }
        }
        receipt.footer += `\nPizze: ${plates}\nData inserimento: `
        const insDate = moment()
        if (insDate.format('YYYY-MM-DD') === order.hour.split('T')[0]) {
            receipt.footer += `${insDate.format('YYYY-MM-DD HH:mm')}\n`
        } else {
            receipt.footer += `${order.hour.split('T')[0]}\n`
        }

        receipt.footerOrders = ""
        if (numOrders) {
            receipt.footerOrders += `Ordini effettuati: ${numOrders}\n`
        } else if (typeof numOrders === 'number' && numOrders === 0) {
            receipt.footerOrders += 'Nuovo cliente\n'
        }

        receipt.qr = "";
        if (order.reg.address && order.mode === OrderConstants.MODE_CONSEGNA) {
            receipt.qr = `${process.env.REACT_APP_BACKEND_URL}/driver/orders/${user.username}/?id=${order.seq_value}`
        }

        return receipt
    }

    async function print(order = null, numOrders = null) {
        if (socket.current.readyState === 1) {
            // per ogni stampante di tipo COMANDE
            for (let i in printers["COMANDE"]) {
                console.log(printers["COMANDE"][i])
                if (printers["COMANDE"][i].name && printers["COMANDE"][i].address && printers["COMANDE"][i].port) {
                    const printer = {
                        "title": printers["COMANDE"][i].name,
                        "type": "network",
                        "profile": "default",
                        "char_per_line": "48",
                        "ip_address": printers["COMANDE"][i].address,
                        "port": printers["COMANDE"][i].port
                    }
                    if (order) {
                        const receipt_data = receiptData(order, numOrders)
                        console.log(receipt_data)
                        const socket_data = {
                            printer: printer,
                            text: receipt_data
                        }
                        socket.current.send(JSON.stringify({
                            type: 'print-receipt',
                            data: socket_data
                        }))
                    }
                } else {
                    alert('Impossibile stampare! Assicurati di aver specificato i dati della stampante e che questi corrispondano a quelli presenti sul server PPP.')
                }
            }
        } else {
            alert('Impossibile stampare! Assicurati che il server PPP sia connesso. In caso affermativo, prova a ricaricare la pagina.')
        }
    }

    const todayOrders = useRef(null)

    useEffect(() => {
        async function retrieveOrders() {
            console.log("servizi in esecuzione")
            if (user?.username) {
                await Services.getOrders(user.username, moment().format('YYYY-MM-DD'))
                    .then((response) => {
                        const orders = response.data.orders
                        todayOrders.current = response.data.orders

                        // NOTIFICA PARTENZA ORDINE
                        let filteredOrders = [...orders]
                        filteredOrders = filteredOrders.filter((value) => {
                            if (value.platform !== OrderConstants.NO_PLATFORM && value.driver) {
                                const now = moment()
                                const prev = moment().subtract(20, 'seconds')
                                //console.log(prev.format('HH:mm:ss'), moment(value.driver.registration).format('HH:mm:ss'), now.format('HH:mm:ss'))
                                if (moment(value.driver.registration).isBetween(prev, now, 'second', '(]')) {
                                    return true
                                }
                            }
                            return false
                        })

                        for (let i in filteredOrders) {
                            notify(filteredOrders[i])
                        }

                        // ALERT RITARDO PARTENZA ORDINE
                        filteredOrders = [...orders]
                        filteredOrders = filteredOrders.filter((value) => {
                            if (value.mode === OrderConstants.MODE_CONSEGNA && !value.driver) {
                                const limiteInf = moment(value.hour).add(10, 'minute')
                                const limiteSup = moment(limiteInf).add(20, 'seconds')
                                console.log(prettyHour(value.hour), limiteInf.format('HH:mm:ss'), limiteSup.format('HH:mm:ss'), moment().isBetween(limiteInf, limiteSup, 'seconds', '[)'))
                                if (moment().isBetween(limiteInf, limiteSup, 'seconds', '[)')) {
                                    return true
                                }
                            }
                            return false
                        })

                        for (let i in filteredOrders) {
                            notifyAlert(filteredOrders[i])
                        }

                        // NOTIFICA ORDINE APP
                        if (mode.current && settingsRef.current?.delivery?.app) {  // nella modalità cucina (mode.current === false) e/o se l'app non è abilitata non faccio notifyApp
                            filteredOrders = [...orders]
                            filteredOrders = filteredOrders.filter((value) => {
                                return (value.platform === OrderConstants.APP_PLATFORM && value.status === 'In attesa')
                            })

                            for (let i in filteredOrders) {
                                // visualizzo la notifica (solo se non l'ho già fatto)
                                if (!ordersToConfirm.current.split(",").includes(String(filteredOrders[i].seq_value))) {
                                    // aggiorno la lista degli ordini da confermare: è una stringa di seq_value separati tra loro da una virgola
                                    ordersToConfirm.current = ordersToConfirm.current + "," + String(filteredOrders[i].seq_value)

                                    setAppOrdersConfirmed(filteredOrders[i].seq_value)
                                    notifyApp(filteredOrders[i])
                                }
                            }
                            if (filteredOrders.length > 0) {
                                toggle()  // finché c'è almeno un ordine in attesa continuo a far sentire il suono
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }

        retrieveOrders().then(() => {}, (err) => console.log(err))

        const interval = setInterval(retrieveOrders, 20 * 1000)

        return () => clearInterval(interval)
    }, [user?.username])

    const notify = (order) => toast.success((t) => (
        <span onClick={() => toast.dismiss(t.id)}>
            Ordine #{order.seq_value}
            &nbsp;
            <span className="badge rounded-pill bg-info text-white">{order.platform}</span>
            &nbsp;
            <button type="button" className="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <br/>
            <b>{order.reg?.address}</b>
        </span>),
        { duration: Infinity }
    )

    /* useEffect(() => {
        async function retrieveOrders() {
            console.log("servizio alert in esecuzione")
            if (user?.username) {
                await Services.getOrders(user.username, moment().format('YYYY-MM-DD'))
                    .then((response) => {
                        const orders = response.data.orders

                        let filteredOrders = [...orders]
                        filteredOrders = filteredOrders.filter((value) => {
                            if (value.mode === OrderConstants.MODE_CONSEGNA && !value.driver) {
                                const limiteInf = moment(value.hour).add(10, 'minute')
                                const limiteSup = moment(limiteInf).add(1, 'minute')
                                console.log(prettyHour(value.hour), limiteInf.format('HH:mm'), limiteSup.format('HH:mm'), moment().isBetween(limiteInf, limiteSup, 'minute', '[)'))
                                if (moment().isBetween(limiteInf, limiteSup, 'minute', '[)')) {
                                    return true
                                }
                            }
                            return false
                        })

                        for (let i in filteredOrders) {
                            notifyAlert(filteredOrders[i])
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }

        const interval = setInterval(retrieveOrders, 60 * 1000)

        return () => clearInterval(interval)
    }, [user?.username]) */

    const notifyAlert = (order) => toast.error((t) => (
        <span onClick={() => toast.dismiss(t.id)}>
            <b>ATTENZIONE!</b>
            <br/>
            Ordine #{order.seq_value} {prettyHour(order.hour)} <b>IN RITARDO</b>
            &nbsp;
            <button type="button" className="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <br/>
            {order.reg?.address}
        </span>),
        { duration: Infinity }
    )

    // CONFERMA ORDINE PROVENIENTE DALL'APP
    const ordersToConfirm = useRef("")  // ATTENZIONE! Dentro setInterval gli useState non funzionano quindi vanno usati gli useRef (https://stackoverflow.com/a/72923194)
    const mode = useRef(!user?.admin && sessionStorage.getItem('mode') ? JSON.parse(sessionStorage.getItem('mode')) : true)  // se admin=true o ho fatto il login base allora non guardo la modalità
    const [currentToast, setCurrentToast] = useState(null)
    const [appOrdersConfirmed, setAppOrdersConfirmed] = useState(null)

    const audio = useMemo(() => new Audio(sound), [])
    const toggle = () => audio.play().catch((error) => console.log("Cannot play sound without user interaction first"))
    /* const [playing, setPlaying] = useState(false)
    const toggle = () => setPlaying(!playing)

    useEffect(() => {
        playing ? audio.play() : audio.pause()
    }, [playing])

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false))
        return () => audio.removeEventListener('ended', () => setPlaying(false))
    }, []) */

    /* useEffect(() => {
        async function retrieveOrders() {
            console.log("servizio notifiche ordini app in esecuzione")
            if (mode.current) {  // nella modalità cucina (mode.current === false) non faccio notifyApp
                await Services.getOrders(user.username, moment().format('YYYY-MM-DD'))
                    .then((response) => {
                        const orders = response.data.orders

                        let filteredOrders = [...orders]
                        filteredOrders = filteredOrders.filter((value) => {
                            return (value.platform === OrderConstants.APP_PLATFORM && value.status === 'In attesa')
                        })

                        for (let i in filteredOrders) {
                            // visualizzo la notifica (solo se non l'ho già fatto)
                            if (!ordersToConfirm.current.split(",").includes(String(filteredOrders[i].seq_value))) {
                                // aggiorno la lista degli ordini da confermare: è una stringa di seq_value separati tra loro da una virgola
                                ordersToConfirm.current = ordersToConfirm.current + "," + String(filteredOrders[i].seq_value)

                                setAppOrdersConfirmed(filteredOrders[i].seq_value)
                                notifyApp(filteredOrders[i])
                            }
                        }
                        if (filteredOrders.length > 0) {
                            toggle()  // finché c'è almeno un ordine in attesa continuo a far sentire il suono
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }

        if (user?.username && settings?.delivery?.app) {
            const interval = setInterval(retrieveOrders, 5 * 1000)

            return () => clearInterval(interval)
        }
    }, [user?.username, settings]) */

    const notifyApp = (order) => toast((t) => (
        <span>
            Ordine #{order.seq_value}
            &nbsp;
            <span className="badge rounded-pill bg-info text-white">{order.platform}</span>
            &nbsp;
            <button
                className="btn btn-link rounded-circle btn-sm"
                type="button"
                onClick={() => {
                    setOrderData(order)
                    history.push('/new-order')
                    setCurrentToast(t.id)
                }}
                title="Scegli orario definitivo"
            >
                <i className="fas fa-clock"></i>
            </button>
            &nbsp;
            <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => {
                    ordersToConfirm.current = ordersToConfirm.current.replace(","+String(order.seq_value), "")
                    toast.dismiss(t.id)
                }}>
                    <span aria-hidden="true">&times;</span>
            </button>
            <br/>
            <b>{order.reg?.address}</b>
        </span>),
        { duration: Infinity }
    )

    async function appOrdersConfirm(newOrderData, currentOrder) {
        if (user?.username && newOrderData?.hour && currentOrder?.reg) {
            await Services.confirmAppOrder(user.username, newOrderData, currentOrder.reg.phone, currentOrder.reg.name, currentOrder.reg.address)
                .then((response) => {
                    console.log(response.data)

                    toast.dismiss(currentToast)  // chiudo la notifica
                    print(response.data.order, response.data.numOrders)  // stampo la comanda
                    setAppOrdersConfirmed(response.data.order.seq_value)  // aggiorno la lista ordini in Orders
                })
                .catch((error) => {
                    console.log(error)

                    // chiudo la notifica (verrà riproposta solo se l'ordine è ancora In attesa)
                    ordersToConfirm.current = ordersToConfirm.current.replace(","+String(currentOrder.seq_value), "")
                    toast.dismiss(currentToast)
                })
        }
    }

    // CENTRO MESSAGGI WHATSAPP
    const numMessages = useRef(0)
    const [messages, setMessages] = useState([])
    const [showBadge, setShowBadge] = useState(false)

    useEffect(() => {
        async function retrieveMessages() {
            console.log("servizio messaggi whatsapp in esecuzione")
            await Services.getMessages(settings.general.businessPhone, moment().format('YYYY-MM-DD'))
                .then((response) => {
                    // console.log(response.data.numMessages, numMessages.current, response.data.messages)
                    if (response.data.numMessages > numMessages.current) {
                        numMessages.current = response.data.numMessages
                        setMessages(response.data.messages)
                        notifyMessage()
                        setShowBadge(true)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setMessages([])
                })
        }

        if (user?.username && settings?.general?.businessPhone) {
            const interval = setInterval(retrieveMessages, 20 * 1000)

            return () => clearInterval(interval)
        }
    }, [user?.username, settings])

    const notifyMessage = () => toast.error((t) => (
        <span onClick={() => {toast.dismiss(t.id); setShowBadge(false)}}>
            <b>ATTENZIONE!</b>
            <br/>
            Ci sono nuovi messaggi
            &nbsp;
            <button type="button" className="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </span>),
        { duration: Infinity }
    )

    // GESTIONE PERMESSI AMMINISTRATORE
    const [mail, setMail] = useState("")
    const [password, setPassword] = useState("")
    const [adminPassword, setAdminPassword] = useState("")
    const [hidePassword, setHidePassword] = useState(true)
    const [hideAdminPassword, setHideAdminPassword] = useState(true)
    const closeRef = useRef(null)
    const history = useHistory()

    const onChangeMail = (e) => {
        const mail = e.target.value
        setMail(mail)
    }

    const onChangePassword = (e) => {
        const password = e.target.value
        setPassword(password)
    }

    const onChangeAdminPassword = (e) => {
        const password = e.target.value
        setAdminPassword(password)
    }

    const handleHidePassword = () => {
        setHidePassword(!hidePassword)
    }

    const handleHideAdminPassword = () => {
        setHideAdminPassword(!hideAdminPassword)
    }

    async function updateRole() {
        if (user?.username) {
            Services.updateRole(user.username, adminPassword).then((response) => {
                if (response.data.user?.admin) {
                    login(response.data.user)
                    sessionStorage.removeItem('mode')
                    history.push('/')
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.log(error.response?.data?.error)
            })
        }
        setAdminPassword("")
        setHideAdminPassword(true)
    }

    async function updateProfile() {
        if (user?.username) {
            Services.updateProfile(user.username, mail, password, adminPassword).then((response) => {
                login(response.data.user)
                history.push('/')
            })
            .catch((error) => {
                console.log(error.response?.data?.error)
            })
        }
        setPassword("")
        setHidePassword(true)
        setAdminPassword("")
        setHideAdminPassword(true)
    }

    const lock = () => {
        const loggedInUser = localStorage.getItem('user')
        if (loggedInUser) {
            const newUser = JSON.parse(loggedInUser)
            newUser.admin = user.admin === null ? null : false  // preservo la modalità emergenza
            setUser(newUser)
            localStorage.setItem('user', JSON.stringify(newUser))
            sessionStorage.setItem('mode', JSON.stringify(mode.current))
            history.push('/')
        }
    }

    const emergency = () => {
        const loggedInUser = localStorage.getItem('user')
        if (loggedInUser) {
            const newUser = JSON.parse(loggedInUser)
            newUser.admin = null
            setUser(newUser)
            localStorage.setItem('user', JSON.stringify(newUser))
            sessionStorage.setItem('mode', JSON.stringify(mode.current))
            history.push('/')
        }
    }


    return (
        <div className="App" role="main">
            {user ?
                user.subscriptionStatus ? (
                <div id="wrapper">
                    {/* UTENTE ESISTENTE E IN REGOLA */}

                    {/* <!-- Sidebar --> */}
                    <ul className="navbar-nav bg-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                        {/* <!-- Sidebar - Brand --> */}
                        <li className="sidebar-brand d-flex align-items-center justify-content-center" onClick={emergency}>
                            <div className="sidebar-brand-icon rotate-n-15">
                                <i className="fas fa-shipping-fast"></i>
                            </div>
                            <div className="sidebar-brand-text mx-4">
                                DELIVERY
                                <br />
                                PRO
                            </div>
                        </li>

                        <hr className="sidebar-divider"></hr>

                        <div className="sidebar-heading">
                            Interfacce
                        </div>

                        {mode.current && <>
                            <li className="nav-item">
                                <Link to={"/new-order"} className="nav-link sidebarToggleLinks" onClick={() => {
                                    retrieveSettings().then(() => {}, (err) => console.log(err))
                                    setOrderData(null)
                                }}>
                                    <i className="fas fa-cart-plus fa-fw"></i>
                                    <span>Nuovo Ordine</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to={"/orders"} className="nav-link sidebarToggleLinks">
                                    <i className="fas fa-clipboard-list fa-fw"></i>
                                    <span>Ordini</span>
                                </Link>
                            </li>
                        </>}

                        <li className="nav-item">
                            <Link to={"/drivers-status"} className="nav-link sidebarToggleLinks" onClick={retrieveSettings}>
                                <i className="fas fa-motorcycle fa-fw"></i>
                                <span>Drivers</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to={"/receipt"} className="nav-link sidebarToggleLinks">
                                <i className="fas fa-receipt fa-fw"></i>
                                <span>Ricette</span>
                            </Link>
                        </li>

                        {user?.admin && <>
                            <hr className="sidebar-divider"></hr>

                            <div className="sidebar-heading">
                                Area personale
                            </div>

                            <li className="nav-item">
                                <Link to={"/"} className="nav-link sidebarToggleLinks" onClick={retrieveSettings}>
                                    <i className="fas fa-tachometer-alt fa-fw"></i>
                                    <span>Dashboard</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to={"/report"} className="nav-link sidebarToggleLinks">
                                    <i className="fas fa-table fa-fw"></i>
                                    <span>Report</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to={"/employees"} className="nav-link sidebarToggleLinks">
                                    <i className="fas fa-users fa-fw"></i>
                                    <span>Dipendenti</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to={"/todo"} className="nav-link sidebarToggleLinks">
                                    <i className="fas fa-tasks fa-fw"></i>
                                    <span>Compiti</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to={"/clients"} className="nav-link sidebarToggleLinks">
                                    <i className="fas fa-address-card fa-fw"></i>
                                    <span>Clienti</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to={"/coupons"} className="nav-link sidebarToggleLinks">
                                    <i className="fas fa-tags fa-fw"></i>
                                    <span>Coupon</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to={"/menu"} className="nav-link sidebarToggleLinks" onClick={retrieveSettings}>
                                    <i className="fas fa-book-open fa-fw"></i>
                                    <span>Menu</span>
                                </Link>
                            </li>

                            {/* <li className="nav-item">
                                <span
                                    className="nav-link collapsed"
                                    data-toggle="collapse"
                                    data-target="#haccp"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <i className="fas fa-fw fa-folder"></i>
                                    <span>HACCP</span>
                                </span>
                                <div id="haccp" className="collapse">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        <Link to={"/settings-general"} className="collapse-item sidebarToggleLinks">
                                            <span>Temperature</span>
                                        </Link>
                                        <Link to={"/settings-delivery"} className="collapse-item sidebarToggleLinks">
                                            <span>Pulizie</span>
                                        </Link>
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item">
                                <span
                                    className="nav-link collapsed"
                                    data-toggle="collapse"
                                    data-target="#ricette"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <i className="fas fa-fw fa-folder"></i>
                                    <span>Ricette</span>
                                </span>
                                <div id="ricette" className="collapse">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        <Link to={"/settings-general"} className="collapse-item sidebarToggleLinks">
                                            <span>Aggiungi</span>
                                        </Link>
                                        <Link to={"/settings-delivery"} className="collapse-item sidebarToggleLinks">
                                            <span>Visualizza</span>
                                        </Link>
                                    </div>
                                </div>
                            </li> */}

                            <li className="nav-item">
                                <span
                                    className="nav-link collapsed"
                                    data-toggle="collapse"
                                    data-target="#impostazioni"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <i className="fas fa-folder fa-fw"></i>
                                    <span>Impostazioni</span>
                                </span>
                                <div id="impostazioni" className="collapse">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        <Link to={"/settings-general"} className="collapse-item sidebarToggleLinks">
                                            <span>Generali</span>
                                        </Link>
                                        <Link to={"/settings-delivery"} className="collapse-item sidebarToggleLinks">
                                            <span>Delivery</span>
                                        </Link>
                                        <Link to={"/settings-employees"} className="collapse-item sidebarToggleLinks">
                                            <span>Dipendenti</span>
                                        </Link>
                                    </div>
                                </div>
                            </li>
                        </>}

                        <hr className="sidebar-divider"></hr>

                        <div>
                            <Toaster 
                                position="bottom-left"
                            />
                        </div>

                    </ul>
                    {/* <!-- End of Sidebar --> */}

                    {/* <!-- Content Wrapper --> */}
                    <div id="content-wrapper" className="d-flex flex-column">

                        {/* <!-- Main Content --> */}
                        <div id="content">

                            {/* <!-- Topbar --> */}
                            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                                <div className="col-2 col-sm-4 d-flex justify-content-left">
                                    {/* <!-- Sidebar Toggler --> */}
                                    <button id="sidebarToggleTop" className="btn btn-link rounded-circle mr-3">
                                        <i className="fa fa-bars"></i>
                                    </button>
                                </div>

                                <div className="col-0 col-sm-4 d-flex justify-content-center">
                                    <h4 className="text-dark d-none d-sm-inline">
                                        <Clock format={'HH:mm:ss'} ticking={true} timezone={'Europe/Rome'} />
                                    </h4>
                                </div>

                                <div className="col-10 col-sm-4 d-flex justify-content-right">
                                    {/* <!-- Topbar Navbar --> */}
                                    <ul className="navbar-nav ml-auto">
                                        {/* <!-- Nav Item - Alerts --> */}
                                        {settings?.general?.businessPhone &&
                                        <li className="nav-item dropdown no-arrow mx-1">
                                            <span
                                                className="nav-link dropdown-toggle"
                                                id="alertsDropdown"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                title="Centro messaggi"
                                            >
                                                <i className="fas fa-comments fa-sm fa-fw"></i>
                                                {showBadge && <span className="badge badge-danger badge-counter">!</span>}
                                            </span>
                                            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                                                <h6 className="dropdown-header">
                                                    Centro messaggi
                                                </h6>
                                                {messages.map((m, i) => {
                                                    return (
                                                        <div className="dropdown-item d-flex align-items-center" key={`messaggio${i}`}>
                                                            <div className="mr-3 d-none d-md-inline">
                                                                <div className="icon-circle bg-primary">
                                                                    <i className="fas fa-comment text-white"></i>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="small text-gray-500" style={{ textTransform: 'capitalize' }}>
                                                                    {moment(m.insertDate+"+00").locale('it').format('LLLL')}
                                                                </div>
                                                                <b>{m.sender} - {m.senderName}</b><br />
                                                                {m.body}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {messages.length === 0 && <div className="dropdown-item text-center small text-gray-500">Non ci sono messaggi</div>}
                                            </div>
                                        </li>
                                        }

                                        {/* <!-- Nav Item - Printers --> */}
                                        <li className="nav-item mx-1">
                                            <Link to={"/settings-printers"} className="nav-link sidebarToggleLinks" title="Stampanti">
                                                <i className="fas fa-print fa-sm fa-fw"></i>
                                                {!connectionStatus.includes("connesso") && <span className="badge badge-danger badge-counter">!</span>}
                                            </Link>
                                        </li>

                                        {/* <!-- Nav Item - User Information --> */}
                                        <li className="nav-item dropdown no-arrow">
                                            <span
                                                className="nav-link dropdown-toggle"
                                                id="userDropdown"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                title={user?.mail ? `Siete registrati come: ${user.mail.split("@")[0]}` : "Account"}
                                            >
                                                <i className="fas fa-user fa-sm fa-fw"></i>
                                            </span>
                                            {/* <!-- Dropdown - User Information --> */}
                                            <div className="col-4 dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                                <button
                                                    className="dropdown-item"
                                                    data-toggle="modal"
                                                    data-target="#profiloModal"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    disabled={!user?.admin}
                                                    onClick={() => setMail(user?.mail)}
                                                >
                                                    <i className="fas fa-user-cog fa-sm fa-fw mr-2 text-gray-400"></i>
                                                    Account
                                                </button>

                                                <a
                                                    className="dropdown-item"
                                                    href={`${process.env.REACT_APP_STRIPE_BILLING_PORTAL}?prefilled_email=${encodeURIComponent(user?.mail)}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <i className="fas fa-money-bills fa-sm fa-fw mr-2 text-gray-400"></i>
                                                    Abbonamento
                                                </a>

                                                <a
                                                    className="dropdown-item"
                                                    href="https://github.com/delivery-pro/delivery-pro-wiki/wiki"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <i className="fas fa-question-circle fa-sm fa-fw mr-2 text-gray-400"></i>
                                                    Guida
                                                </a>

                                                <div className="dropdown-divider"></div>

                                                {user?.admin ? <>
                                                    <span
                                                        className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            mode.current = true
                                                            lock()
                                                        }}
                                                    >
                                                        <i className="fas fa-headset fa-sm fa-fw mr-2 text-gray-400"></i>
                                                        Mod. Ordini
                                                    </span>
                                                    <span
                                                        className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            mode.current = false
                                                            lock()
                                                        }}
                                                    >
                                                        <i className="fas fa-pizza-slice fa-sm fa-fw mr-2 text-gray-400"></i>
                                                        Mod. Cucina
                                                    </span>
                                                </> : <>
                                                    {!mode.current ? <span
                                                        className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            mode.current = true
                                                            lock()
                                                        }}
                                                    >
                                                        <i className="fas fa-headset fa-sm fa-fw mr-2 text-gray-400"></i>
                                                        Mod. Ordini
                                                    </span> : <span
                                                        className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            mode.current = false
                                                            lock()
                                                        }}
                                                    >
                                                        <i className="fas fa-pizza-slice fa-sm fa-fw mr-2 text-gray-400"></i>
                                                        Mod. Cucina
                                                    </span>}
                                                    <button
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#sbloccaModal"
                                                        data-backdrop="static"  //non si può uscire cliccando fuori
                                                        data-keyboard="false"   //non si può uscire premendo Esc
                                                        onClick={() => {
                                                            setTimeout(() => { 
                                                                document.getElementById("adminPassword").focus()
                                                            }, 500)
                                                        }}
                                                    >
                                                        <i className="fas fa-lock-open fa-sm fa-fw mr-2 text-gray-400"></i>
                                                        Gestione
                                                    </button>
                                                </>}

                                                <button
                                                    className="dropdown-item"
                                                    data-toggle="modal"
                                                    data-target="#logoutModal"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                >
                                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                                    Logout
                                                </button>
                                            </div>
                                        </li>

                                    </ul>
                                </div>

                            </nav>
                            {/* <!-- End of Topbar --> */}

                            {user?.admin ? (
                                <Switch>
                                    <Route exact path="/new-order" render={(props) =>
                                        <Main {...props} user={user} print={print} setOrderData={setOrderData} orderData={orderData} appOrdersConfirm={appOrdersConfirm}
                                            settings={settings} menu={menu} clients={clients} setTriggerClientsUpdate={setTriggerClientsUpdate} orders={todayOrders} />
                                        // Usa settings per:
                                        // - calcolare la distanza (legge l'indirizzo della pizzeria)
                                        // - la verifica orari (legge l'orario di apertura e gli slots)
                                        // - leggere le spese di consegna
                                        // - leggere le piattaforme
                                        // - la mappa delle consegne (legge le coordinate della pizzeria e le posizioni dei fattorini)
                                    }></Route>

                                    <Route exact path="/orders" render={(props) =>
                                        <Orders {...props} user={user} print={print} setOrderData={modify} appOrdersConfirmed={appOrdersConfirmed} orders={todayOrders} />
                                    }></Route>

                                    <Route exact path="/drivers-status" render={(props) =>
                                        <DriversStatus {...props} user={user} settings={settings} setMap={setMap} orders={todayOrders} />
                                        // Usa settings per la mappa delle consegne (legge le coordinate della pizzeria e le posizioni dei fattorini)
                                    }></Route>

                                    <Route exact path="/receipt" render={(props) =>
                                        <Ricette {...props} user={user} />
                                    }></Route>

                                    <Route exact path="/settings-printers" render={(props) =>
                                        <SettingsPrinters {...props} user={user} status={connectionStatus} />
                                    }></Route>


                                    <Route exact path="/" render={(props) =>
                                        <Dashboard {...props} user={user} settings={settings} />
                                        // Usa settings per leggere il cap e per la mappa delle consegne (legge le coordinate della pizzeria e le posizioni dei fattorini)
                                    }></Route>

                                    <Route exact path="/report" render={(props) =>
                                        <Report {...props} user={user} />
                                    }></Route>

                                    <Route exact path="/employees" render={(props) =>
                                        <Employees {...props} user={user} />
                                    }></Route>

                                    <Route exact path="/todo" render={(props) =>
                                        <Compiti {...props} user={user} />
                                    }></Route>

                                    <Route exact path="/clients" render={(props) =>
                                        <Clients {...props} user={user} />
                                    }></Route>

                                    <Route exact path="/coupons" render={(props) =>
                                        <Coupon {...props} user={user} />
                                    }></Route>

                                    <Route exact path="/menu" render={(props) =>
                                        <Menu {...props} user={user} settings={settings} setTriggerMenuUpdate={setTriggerMenuUpdate} />
                                        // Usa settings per leggere le sotto-categorie
                                    }></Route>

                                    <Route exact path="/settings-general" render={(props) =>
                                        <SettingsGeneral {...props} user={user} />
                                    }></Route>

                                    <Route exact path="/settings-delivery" render={(props) =>
                                        <SettingsDelivery {...props} user={user} />
                                    }></Route>

                                    <Route exact path="/settings-employees" render={(props) =>
                                        <SettingsEmployees {...props} user={user} />
                                    }></Route>


                                    <Route render={() =>
                                        <div className="container-fluid">
                                            <div className="text-center">
                                                <div className="error mx-auto" data-text="404">404</div>
                                                <p className="lead text-gray-800 mb-5">Pagina inesistente</p>
                                                <Link to={"/"} className="small">
                                                    <span>&larr; Ritorna alla home</span>
                                                </Link>
                                            </div>
                                        </div>
                                    }>
                                    </Route>
                                </Switch>
                            ) : (mode.current ? (
                                    <Switch>
                                        <Route exact path={["/", "/new-order"]} render={(props) =>
                                            <Main {...props} user={user} print={print} setOrderData={setOrderData} orderData={orderData} appOrdersConfirm={appOrdersConfirm}
                                                settings={settings} menu={menu} clients={clients} setTriggerClientsUpdate={setTriggerClientsUpdate} orders={todayOrders} />
                                        }></Route>

                                        <Route exact path="/orders" render={(props) =>
                                            <Orders {...props} user={user} print={print} setOrderData={modify} appOrdersConfirmed={appOrdersConfirmed} orders={todayOrders} />
                                        }></Route>

                                        <Route exact path="/drivers-status" render={(props) =>
                                            <DriversStatus {...props} user={user} settings={settings} setMap={setMap} orders={todayOrders} />
                                        }></Route>

                                        <Route exact path="/receipt" render={(props) =>
                                            <Ricette {...props} user={user} />
                                        }></Route>

                                        <Route exact path="/settings-printers" render={(props) =>
                                            <SettingsPrinters {...props} user={user} status={connectionStatus} />
                                        }></Route>

                                        <Route render={() =>
                                            <div className="container-fluid">
                                                <div className="text-center">
                                                    <div className="error mx-auto" data-text="404">404</div>
                                                    <p className="lead text-gray-800 mb-5">Pagina inesistente</p>
                                                    <Link to={"/"} className="small">
                                                        <span>&larr; Ritorna alla home</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        }>
                                        </Route>
                                    </Switch>
                                ) : (
                                    <Switch>
                                        <Route exact path={["/", "/drivers-status"]} render={(props) =>
                                            <DriversStatus {...props} user={user} settings={settings} setMap={setMap} orders={todayOrders} />
                                        }></Route>

                                        <Route exact path="/receipt" render={(props) =>
                                            <Ricette {...props} user={user} />
                                        }></Route>

                                        <Route exact path="/settings-printers" render={(props) =>
                                            <SettingsPrinters {...props} user={user} status={connectionStatus} />
                                        }></Route>

                                        <Route render={() =>
                                            <div className="container-fluid">
                                                <div className="text-center">
                                                    <div className="error mx-auto" data-text="404">404</div>
                                                    <p className="lead text-gray-800 mb-5">Pagina inesistente</p>
                                                    <Link to={"/"} className="small">
                                                        <span>&larr; Ritorna alla home</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        }>
                                        </Route>
                                    </Switch>
                                )
                            )}

                        </div>
                        {/* <!-- End of Main Content --> */}

                        {/* <!-- Footer --> */}
                        <footer className="sticky-footer bg-white">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright &copy; Delivery PRO {moment().year()}</span>
                                </div>
                            </div>
                        </footer>
                        {/* <!-- End of Footer --> */}

                    </div>
                    {/* <!-- End of Content Wrapper --> */}

                    {/* <!-- Logout Modal--> */}
                    <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Vuoi davvero uscire?</h5>
                                    <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body container-fluid text-dark">
                                    <p>Clicca su "Logout" per uscire.</p>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary" type="button" data-dismiss="modal">Annulla</button>
                                    <a className="btn btn-primary" href="/" onClick={logout}>Logout</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Sblocca Modal--> */}
                    <div className="modal fade" id="sbloccaModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Sblocca area personale</h5>
                                    <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body container-fluid text-dark">
                                    <div className="form-group">
                                        <label htmlFor="adminPassword">Inserisci la password di amministratore:</label>
                                        <div className="input-group">
                                            <input
                                                id="adminPassword"
                                                type={hideAdminPassword ? "password" : "text"}
                                                className="form-control form-control-user border-right-0"
                                                placeholder="Password"
                                                value={adminPassword}
                                                onChange={onChangeAdminPassword}
                                                aria-required="true"
                                                onKeyDown={(event) => {
                                                    if(event.key === "Enter") {
                                                        updateRole()
                                                        closeRef.current.click()
                                                    }
                                                }}
                                            ></input>
                                            <span className="input-group-append">
                                                <div className="input-group-text bg-transparent">
                                                    <i
                                                        className={hideAdminPassword ? "fas fa-eye" : "fas fa-eye-slash"}
                                                        style={{ cursor: "pointer" }}
                                                        onClick={handleHideAdminPassword}
                                                    ></i>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary" type="button" data-dismiss="modal" ref={closeRef}>Chiudi</button>
                                    <button className="btn btn-primary" type="button" data-dismiss="modal" onClick={updateRole}>Sblocca</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Profilo Modal--> */}
                    <div className="modal fade" id="profiloModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Modifica Account</h5>
                                    <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body container-fluid text-dark">
                                    <div className="form-group">
                                        <label htmlFor="username">Modifica la tua email:</label>
                                        <input
                                            id="username"
                                            type="email"
                                            className="form-control form-control-user"
                                            placeholder="Username"
                                            value={mail}
                                            onChange={onChangeMail}
                                        ></input>
                                    </div>
                                    <br />
                                    <h5>Modifica le tue password:</h5>
                                    <span>Per ogni campo, inserisci un nuovo valore se vuoi modificarlo altrimenti lascialo vuoto per mantenerne il valore attuale.</span>
                                    <br /><br />
                                    <div className="form-group">
                                        <label htmlFor="passwordChange">Nuova password per te e i tuoi dipendenti:</label>
                                        <div className="input-group">
                                            <input
                                                id="passwordChange"
                                                type={hidePassword ? "password" : "text"}
                                                className="form-control form-control-user border-right-0"
                                                placeholder="Password"
                                                value={password}
                                                onChange={onChangePassword}
                                                aria-required="true"
                                            ></input>
                                            <span className="input-group-append">
                                                <div className="input-group-text bg-transparent">
                                                    <i
                                                        className={hidePassword ? "fas fa-eye" : "fas fa-eye-slash"}
                                                        style={{ cursor: "pointer" }}
                                                        onClick={handleHidePassword}
                                                    ></i>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="adminPasswordChange">Nuova password per la tua area personale:</label>
                                        <div className="input-group">
                                            <input
                                                id="adminPasswordChange"
                                                type={hideAdminPassword ? "password" : "text"}
                                                className="form-control form-control-user border-right-0"
                                                placeholder="Password area personale"
                                                value={adminPassword}
                                                onChange={onChangeAdminPassword}
                                                aria-required="true"
                                            ></input>
                                            <span className="input-group-append">
                                                <div className="input-group-text bg-transparent">
                                                    <i
                                                        className={hideAdminPassword ? "fas fa-eye" : "fas fa-eye-slash"}
                                                        style={{ cursor: "pointer" }}
                                                        onClick={handleHideAdminPassword}
                                                    ></i>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary" type="button" data-dismiss="modal">Chiudi</button>
                                    <button className="btn btn-primary" type="button" data-dismiss="modal" onClick={updateProfile}>Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                ) : (
                    <div className="container">
                        {/* UTENTE ESISTENTE MA NON IN REGOLA */}
                        <div className="row justify-content-center">
                            <Switch>
                                <Route render={() =>
                                    <div className="container-fluid">
                                        <div className="text-center">
                                            <div style={{color: "#5a5c69", fontSize: "7rem"}}>Attenzione</div>
                                            <p className="lead text-gray-800 mb-5">Sembra che tu non sia in regola con l'abbonamento</p>
                                            <a
                                                className="small"
                                                href={`${process.env.REACT_APP_STRIPE_BILLING_PORTAL}?prefilled_email=${encodeURIComponent(user?.mail)}`}
                                                onClick={() => subscriptions(user)}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Controlla qui &rarr;
                                            </a>
                                            <br />
                                            <a
                                                className="small"
                                                href="/"
                                                onClick={logout}
                                            >
                                                Ritorna al login
                                            </a>
                                        </div>
                                    </div>
                                }></Route>
                            </Switch>
                        </div>
                    </div>
                ) : (
                    <div className="container">
                        {/* UTENTE NON ESISTENTE */}
                        <div className="row justify-content-center">
                            <div className="col-xl-10 col-lg-12 col-md-9">
                                <Switch>
                                    <Route exact path="/" render={(props) =>
                                        <Login {...props} login={login} />
                                    }>
                                    </Route>
                                    <Route exact path="/register" render={(props) =>
                                        <Register {...props} login={login} />
                                    }>
                                    </Route>
                                    <Route render={(props) =>
                                        <Login {...props} login={login} />
                                    }>
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default App
