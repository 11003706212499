import React, { useEffect, useState, useMemo } from 'react'
import Countdown from 'react-countdown'
import { QRCodeSVG } from 'qrcode.react'

import MapContainer from './MapContainer'
import Services from '../services.js'
import OrderConstants from '../enums/OrderConstants'
import sound from '../returning.mp3'

const moment = require('moment')
const prettyHour = (hour) => {
    return hour.split('T')[1].substr(0, 5)
}


const DriversStatus = (props) => {
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [orders, setOrders] = useState([])
    const [status, setStatus] = useState({})
    const [show, setShow] = useState(false)
    const [currentDriverName, setCurrentDriverName] = useState('')
    const [currentDriverOrders, setCurrentDriverOrders] = useState([])
    const [currentDriverTotal, setCurrentDriverTotal] = useState(0.0)

    const audio = useMemo(() => new Audio(sound), [])
    const toggle = () => audio.play().catch((error) => console.log("Cannot play sound without user interaction first"))
    /* const [playing, setPlaying] = useState(false)
    const toggle = () => setPlaying(!playing)

    useEffect(() => {
        playing ? audio.play() : audio.pause()
    }, [playing])

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false))
        return () => audio.removeEventListener('ended', () => setPlaying(false))
    }, []) */

    async function retrieveOrdersStatusByDate() {
        console.log("recuperati stati degli ordini")
        if (props.user?.username && date) {
            return Services.getOrders(props.user.username, date)
                .then((response) => {
                    const orders = response.data.orders
                    setOrders(orders)
                    //console.log(orders)

                    let filteredOrders = [...orders]
                    filteredOrders = filteredOrders.filter((value) => {
                        if (value.driver && value.mode === OrderConstants.MODE_CONSEGNA) {
                            return true
                        }
                        return false
                    })
                    // console.log(filteredOrders)

                    let dict = {}
                    for (let i in filteredOrders) {
                        const driverName = filteredOrders[i].driver.name
                        if (dict[driverName]) {
                            dict[driverName] = [...dict[driverName], filteredOrders[i]]
                        } else {
                            dict[driverName] = [filteredOrders[i]]
                        }
                    }
                    setStatus(dict)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const onChangeDate = (e) => {
        const date = e.target.value
        if (date) {
            const newDate = moment(date).format('YYYY-MM-DD')
            setDate(newDate)
        }
    }
    const handleIncrease = () => {
        if (date) {
            const newDate = moment(date).add(1, 'd').format('YYYY-MM-DD')
            setDate(newDate)
        }
    }
    const handleDecrease = () => {
        if (date) {
            const newDate = moment(date).subtract(1, 'd').format('YYYY-MM-DD')
            setDate(newDate)
        }
    }
    const dateToday = () => {
        if (date !== moment().format('YYYY-MM-DD')) {
            setDate(moment().format('YYYY-MM-DD'))
        } else {
            retrieveOrdersStatusByDate().then(() => {}, (err) => console.log(err))
        }
    }

    useEffect(() => {
        async function retrieveOrdersStatus() {
            console.log("servizio in esecuzione")
            if (props.orders?.current) {
            // if (props.user?.username) {
            //     return Services.getOrders(props.user.username, moment().format('YYYY-MM-DD'))
            //         .then((response) => {
            //             const orders = response.data.orders
                        const orders = props.orders.current
                        setOrders(orders)
                        //console.log(orders)

                        let filteredOrders = [...orders]
                        filteredOrders = filteredOrders.filter((value) => {
                            if (value.driver && value.mode === OrderConstants.MODE_CONSEGNA) {
                                if (value.driver.completed) {
                                    // notifica audio
                                    const now = moment()
                                    const prev = moment().subtract(20, 'seconds')
                                    //console.log(prev.format('HH:mm:ss'), moment(value.driver.completed).format('HH:mm:ss'), now.format('HH:mm:ss'))
                                    if (moment(value.driver.completed).isBetween(prev, now, 'second', '(]')) {
                                        toggle()
                                    }
                                }

                                return true
                            }
                            return false
                        })
                        //console.log(filteredOrders)

                        let dict = {}
                        for (let i in filteredOrders) {
                            const driverName = filteredOrders[i].driver.name
                            if (dict[driverName]) {
                                dict[driverName] = [...dict[driverName], filteredOrders[i]]
                            } else {
                                dict[driverName] = [filteredOrders[i]]
                            }
                        }
                        setStatus(dict)
            //         })
            //         .catch((error) => {
            //             console.log(error)
            //         })
            }
        }

        retrieveOrdersStatusByDate().then(() => {}, (err) => console.log(err))

        if (date === moment().format('YYYY-MM-DD')) {
            const interval = setInterval(retrieveOrdersStatus, 20 * 1000)

            return () => clearInterval(interval)
        }
    }, [date])

    const renderDriversCards = () => {
        let render = []
        let cards = []
        for(let key in status) {
            if (status[key].length > 0) {
                // calcolo il totale di tutti gli ordini del fattorino
                let driverTotal = 0.0
                for (let i in status[key]) {
                    if (status[key][i].payment !== "Pagato" && status[key][i].payment !== "Bancomat") {
                        driverTotal += status[key][i].total
                    }
                }

                // ordino per orario di registrazione
                let sorted = [...status[key]]
                sorted.sort((a, b) => {
                    if (prettyHour(a.driver.registration) < prettyHour(b.driver.registration)) {
                        return -1
                    }
                    if (prettyHour(a.driver.registration) > prettyHour(b.driver.registration)) {
                        return 1
                    }
                    return 0
                })

                // il timer va interrotto e aggiornato con quello del nuovo ordine appena viene preso in carico un altro ordine
                const last = sorted[sorted.length - 1]

                let st = ''
                let color = "-info"  // rosso in consegna, verde di ritorno, azzurro concluso
                let returnTime = null
                let arrivalTime = null
                let end = null
                if (last.driver.completed) {
                    if (last.reg.reverseDistance) {
                        returnTime = last.reg.reverseDistance.time.split(' ')
                    } else {
                        returnTime = last.reg.distance.time.split(' ')
                    }

                    if (returnTime.length > 2) {
                        end = moment(last.driver.completed).add(returnTime[0], 'h').add(returnTime[2], 'm').format('YYYY-MM-DDTHH:mm:ss')
                    } else {
                        end = moment(last.driver.completed).add(returnTime[0], 'm').format('YYYY-MM-DDTHH:mm:ss')
                    }

                    if (moment().isAfter(end)) {
                        st = 'Concluso'
                    } else {
                        st = 'Di ritorno'
                        color = "-success"
                    }
                } else {
                    arrivalTime = last.reg.distance.time.split(' ')

                    if (arrivalTime.length > 2) {
                        end = moment(last.driver.registration).add(arrivalTime[0], 'h').add(arrivalTime[2], 'm').format('YYYY-MM-DDTHH:mm:ss')
                    } else {
                        end = moment(last.driver.registration).add(arrivalTime[0], 'm').format('YYYY-MM-DDTHH:mm:ss')
                    }

                    st = 'In consegna'
                    color = "-danger"
                }
                // console.log(last.reg, returnTime, arrivalTime, end)

                if (st !== 'Concluso' || (st === 'Concluso' && date !== moment().format('YYYY-MM-DD')) || (st === 'Concluso' && date === moment().format('YYYY-MM-DD') && show)) {
                    const cardId = key.replaceAll(" ", "_") + last.seq_value
                    const card = (
                        <div key={cardId} className="col-xl-3 col-md-6 mb-4">
                            <div className={"driverCard card shadow border-left"+color}>
                                <div className="d-block card-header">
                                    <div className="row">
                                        <div className="col-10 d-flex justify-content-left">
                                            <a
                                                href={`#${cardId}`}
                                                data-toggle="collapse"
                                                role="button"
                                                aria-expanded="true"
                                                aria-controls="collapseCardExample"
                                            >
                                                <h6 className={"m-0 font-weight-bold text"+color}>{key} #{last.seq_value}</h6>
                                            </a>
                                        </div>
                                        {(props.user?.admin !== null) &&
                                        <div className="col-2 d-flex justify-content-end">
                                            <button
                                                type="button"
                                                className="btn btn-link rounded-circle btn-sm"
                                                data-toggle="modal"
                                                data-target="#ordersModal"
                                                onClick={() => { setCurrentDriverOrders(sorted); setCurrentDriverTotal(driverTotal); setCurrentDriverName(key) }}
                                                title="Ordini"
                                            >
                                                <i className="fa fa-list-ul" aria-hidden="true"></i>
                                            </button>
                                        </div>}
                                    </div>
                                </div>

                                <div className="card-body show" id={cardId}>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="row no-gutters align-items-center text-dark">
                                                <div className="col-auto">
                                                    <span><b>Orario:</b> {prettyHour(last.hour)}</span><br />
                                                    <span><b>Indirizzo:</b> {last.reg.address}</span><br />
                                                    <span><b>Andata:</b> {last.reg.distance.space} - {last.reg.distance.time}</span><br />
                                                    {last.reg.reverseDistance && <>
                                                        <span><b>Ritorno:</b> {last.reg.reverseDistance.space} - {last.reg.reverseDistance.time}</span><br />
                                                    </>}
                                                    <span><b>Stato:</b> {st}</span>
                                                </div>
                                            </div>
                                            {st === 'Di ritorno' && <div className="col-auto text-center">
                                                {/* Questo timer parte appena il fattorino preme ordine completato */}
                                                <Countdown
                                                    date={end}
                                                    renderer={({ hours, minutes, seconds, completed }) => {
                                                        if (completed) {
                                                            // Render a completed state
                                                            return <></>
                                                        } else {
                                                            // Render a countdown
                                                            let width = 0
                                                            if (returnTime.length > 2) {
                                                                width = (hours*60*60 + minutes*60 + seconds) / (returnTime[0]*60*60 + returnTime[2]*60)
                                                            } else {
                                                                width = (hours*60*60 + minutes*60 + seconds) / (returnTime[0]*60)
                                                            }
                                                            return (
                                                                <>
                                                                    <br /><br />
                                                                    <div className="progress progress-sm mb-2">
                                                                        <div
                                                                            className={"progress-bar bg"+color}
                                                                            role="progressbar"
                                                                            style={{ width: `${(width*100).toFixed(0)}%` }}
                                                                        ></div>
                                                                    </div>
                                                                    <span className="form-text text-muted">Ritorno previsto tra</span>
                                                                    <h1>{hours > 0 && `${String(hours).padStart(2, '0')}:`}{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</h1>
                                                                </>
                                                            )
                                                        }
                                                    }}
                                                />
                                            </div>}
                                            {st === 'In consegna' && <div className="col-auto text-center">
                                                {/* Questo timer parte appena il fattorino registra la partenza dell'ordine */}
                                                <br /><br />
                                                <Countdown
                                                    date={end}
                                                    renderer={({ hours, minutes, seconds, completed }) => {
                                                        if (completed) {
                                                            // Render a completed state
                                                            return (
                                                                <>
                                                                    <div className="progress progress-sm mb-2">
                                                                        <div
                                                                            className={"progress-bar bg"+color}
                                                                            role="progressbar"
                                                                            style={{ width: "0%" }}
                                                                        ></div>
                                                                    </div>
                                                                    <span className="form-text text-muted">Non ancora consegnato</span>
                                                                </>
                                                            )
                                                        } else {
                                                            // Render a countdown
                                                            let width = 0
                                                            if (arrivalTime.length > 2) {
                                                                width = (hours*60*60 + minutes*60 + seconds) / (arrivalTime[0]*60*60 + arrivalTime[2]*60)
                                                            } else {
                                                                width = (hours*60*60 + minutes*60 + seconds) / (arrivalTime[0]*60)
                                                            }
                                                            return (
                                                                <>
                                                                    <div className="progress progress-sm mb-2">
                                                                        <div
                                                                            className={"progress-bar bg"+color}
                                                                            role="progressbar"
                                                                            style={{ width: `${(width*100).toFixed(0)}%` }}
                                                                        ></div>
                                                                    </div>
                                                                    <span className="form-text text-muted">Consegna prevista tra</span>
                                                                    <h1>{hours > 0 && `${String(hours).padStart(2, '0')}:`}{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</h1>
                                                                </>
                                                            )
                                                        }
                                                    }}
                                                />
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                    cards.push({end: end, status: st, card: card})
                }
            }
        }

        // il fattorino che sta tornando è sempre all'inizio: ordino prima per ora di ritorno (da quello che torna prima a quello che torna per ultimo)
        // e poi per stato (C,D,I) perché quelli in consegna non hanno come end il tempo di ritorno
        cards.sort((a, b) => {
            if (a.end < b.end) {
                return -1
            }
            if (a.end > b.end) {
                return 1
            }
            return 0
        })
        cards.sort((a, b) => {
            if (a.status < b.status) {
                return -1
            }
            if (a.status > b.status) {
                return 1
            }
            return 0
        })
        for(let i in cards) {
            render.push(cards[i].card)
        }

        return render
    }

    return (
        <div className="container-fluid">

            {/* <!-- Data
                - Quando un fattorino prende in carico una consegna in quel giorno, viene automaticamente aggiunto al giorno
            --> */}
            <div className="form-group row justify-content-center">
                <div className="col-4 d-none d-xl-inline d-lg-inline"></div>

                {props.user?.admin ? (
                    <div className="input-group col-xl-4 col-lg-4 col-md-4 col-sm-8 col-12 mb-lg-0 mb-3 d-flex justify-content-center">
                        <div className="input-group-prepend">
                            <button type="button" className="btn btn-primary btn-circle" onClick={handleDecrease}>
                                <i className="fas fa-arrow-left"></i>
                            </button>
                        </div>
                        <input
                            id="data"
                            type="date"
                            className="form-control form-control-user"
                            placeholder="Inserisci la data"
                            value={date}
                            onChange={onChangeDate}
                        ></input>
                        <div className="input-group-append">
                            <button type="button" className="btn btn-primary btn-circle" onClick={handleIncrease}>
                                <i className="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="input-group col-xl-4 col-lg-4 col-md-4 col-sm-8 col-12 mb-lg-0 mb-3 d-flex justify-content-center">
                        <input
                            id="data"
                            type="date"
                            className="form-control form-control-user"
                            placeholder="Data di oggi"
                            value={date}
                            disabled
                        ></input>
                    </div>
                )}

                <div className="col-4 d-none d-xl-inline d-lg-inline"></div>
            </div>
            <div className="form-group row justify-content-center">
                <div className="col-3 d-flex justify-content-center">
                    {/* <!-- BOTTONE MAPPA (in modalità admin/true e base/false) --> */}
                    {props.user?.admin !== null &&
                        <button
                            className="btn btn-success"
                            type="button"
                            data-toggle="modal"
                            data-target="#viewProgrammazioneConsegne"
                            data-backdrop="static"  //non si può uscire cliccando fuori
                            data-keyboard="false"   //non si può uscire premendo Esc
                            onClick={() => props.setMap(true)}
                            title="Mappa"
                        >
                            <i className="fas fa-map-marked-alt"></i>
                            <span className="ml-2 d-none d-sm-inline">Mappa</span>
                        </button>
                    }
                </div>

                <div className="col-3 d-flex justify-content-center">
                    {/* <!-- BOTTONE SET DATA OGGI (solo in modalità admin/true) --> */}
                    {props.user?.admin &&
                        <button type="button" className="btn btn-primary" onClick={dateToday} title="Oggi">
                            <i className="fas fa-calendar-day"></i>
                            <span className="ml-2 d-none d-sm-inline">Oggi</span>
                        </button>
                    }
                </div>

                <div className="col-3 d-flex justify-content-center">
                    {/* <!-- BOTTONE MOSTRA/NASCONDI ORDINI COMPLETATI (in modalità admin/true e base/false) --> */}
                    {props.user?.admin !== null &&
                        <button type="button" className="btn btn-warning" onClick={() => setShow(!show)} disabled={date !== moment().format('YYYY-MM-DD')} title="Conclusi">
                            {show ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                            <span className="ml-2 d-none d-sm-inline">Conclusi</span>
                        </button>
                    }
                </div>
            </div>

            {/* <!-- CARD FATTORINO --> */}
            <div className="row">
                {renderDriversCards()}
            </div>

            {/* <!-- Modal ordini fattorino --> */}
            <div className="modal fade" id="ordersModal" tabIndex="-1" role="dialog" aria-labelledby="ordersModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="ordersModalLabel">Ordini di {currentDriverName} ({currentDriverOrders.length})</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { setCurrentDriverOrders([]); setCurrentDriverTotal(0.0); setCurrentDriverName('') }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div>
                                <span><b>Pagamenti incassati (escluso bancomat):</b> € {currentDriverTotal.toFixed(2)}</span>
                            </div>
                            &nbsp;
                            <div className="table-responsive">
                                <table className="table text-dark" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "10%" }}>ID</th>
                                            <th style={{ width: "10%" }}>Orario</th>
                                            <th style={{ width: "45%" }}>Indirizzo</th>
                                            <th style={{ width: "10%" }}>Piattaforma</th>
                                            <th style={{ width: "15%" }}>Importo</th>
                                            <th style={{ width: "10%" }}>Pagamento</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentDriverOrders.map((o, index) => {
                                            return (
                                                <tr key={`tabella-ordini-${index}`}>
                                                    <td>#{o.seq_value}</td>
                                                    <td>{prettyHour(o.hour)}</td>
                                                    <td>{o.reg.address}</td>
                                                    <td>{o.platform}</td>
                                                    <td>€ {o.total.toFixed(2)}</td>
                                                    <td>{o.payment}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => { setCurrentDriverOrders([]); setCurrentDriverTotal(0.0); setCurrentDriverName('') }}
                            >
                                Chiudi
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modale per mappa consegne --> */}
            <div className="modal fade" id="viewProgrammazioneConsegne" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Mappa delle consegne</h5>
                            &nbsp;
                            <div className="dropdown">
                                <button
                                    className="btn btn-link rounded-circle btn-sm dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    title="Scarica DeliveryPro Driver App"
                                >
                                    <i className="fas fa-external-link-alt"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right animated--fade-in" aria-labelledby="dropdownMenuButton">
                                    <span className="dropdown-item disabled">
                                        <QRCodeSVG value="https://github.com/delivery-pro/delivery-pro-wiki/raw/main/DeliveryPro_Driver.apk" />
                                    </span>
                                </div>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.setMap(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            {props.settings?.general?.coordinates ? <MapContainer array={orders} current={null} settings={props.settings} drivers={Object.keys(status)} />
                            : <div className="alert alert-danger" role="alert">
                                <strong>Attenzione!</strong> Inserisci l'indirizzo della pizzeria nelle Impostazioni generali per visualizzare la mappa.
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => props.setMap(false)}>Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DriversStatus
